import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchShoppingListRemove} from '../../../backend/apiCalls';
import {navigateToParametrized} from '../../../lib/url';
import {ShoppingListDesc} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QUERY_PARAMS} from '../../../constants/navigation';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class RemoveShoppingList extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        shoppingList: ShoppingListDesc.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    render() {
        const {shoppingList, location} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Vymazať</Trans>}>
                    <Button className="mx-1" size="small" type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={() => {
                navigateToParametrized(location, null, {[QUERY_PARAMS.ID_SHOPPING_LIST]: undefined});
                reload([GLOBAL_DATA.SHOPPING_LISTS])
            }}
            title={<Trans>Odstránenie nákupného zoznamu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť nákupný zoznam?</Trans>,
                createFetchShoppingListRemove(shoppingList.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť nákupný zoznam.`)}
        />;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(RemoveShoppingList));