import React from 'react';
import PropTypes from 'prop-types';
import {formatNumber} from '../../../../lib/formatting';
import { t } from "@lingui/macro";

/**
 * @fero
 */

class CartAddResponse extends React.PureComponent {
    static propTypes = {
        values: PropTypes.shape({
            products: PropTypes.arrayOf(
                PropTypes.shape({
                        designation: PropTypes.string.isRequired,
                        quantity: PropTypes.number.isRequired,
                        quantityUnits: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
                    }
                ).isRequired
            ).isRequired}
        ).isRequired,
    };

    render() {
        const {values} = this.props;
        return <div>
            <h4>{t`Pridané do košíka`}</h4>
            {values.products.map((item, index) => {
                return <div key={index}>
                    {item.designation + ': ' + formatNumber(item.quantity)}
                    {' '}
                    {item.quantityUnits}
                </div>
            })}
        </div>;
    }

}

export default CartAddResponse;