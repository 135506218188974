import React from 'react';
import Helmet from '../project/Helmet';
import ShoppingListDisplay from './ShoppingListDisplay';
import ShoppingListsList from './ShoppingListsList';
import locationHOC from '../locationProvider/locationHOC';
import { getQueryParamNumber, getQueryParam } from '../../lib/url';
import { QUERY_PARAMS } from '../../constants/navigation';
import ListWithTableLayout from '../project/ListWithTableLayout';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ShoppingListsPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: undefined,
        };
    }

    setDefaultActiveTab = () => {
        const {location} = this.props;
        const shoppingListId = getQueryParamNumber(location, QUERY_PARAMS.ID_SHOPPING_LIST);
        const token = getQueryParam(location, QUERY_PARAMS.SHOPPING_LIST_TOKEN);

        this.setState({
            activeTab: (shoppingListId != null || token != null) ? 'table' : 'list'
        });
    };

    setActiveTab = (key) => {
        this.setState({activeTab: key});
    };

    componentDidMount() {
        this.setDefaultActiveTab();
    }

    componentDidUpdate(prevProps) {
        const {location: prevLocation} = prevProps;
        const {location} = this.props;
        const prevShoppingListId = getQueryParamNumber(prevLocation, QUERY_PARAMS.ID_SHOPPING_LIST);
        const prevToken = getQueryParam(prevLocation, QUERY_PARAMS.SHOPPING_LIST_TOKEN);
        const shoppingListId = getQueryParamNumber(location, QUERY_PARAMS.ID_SHOPPING_LIST);
        const token = getQueryParam(location, QUERY_PARAMS.SHOPPING_LIST_TOKEN);
        if(prevShoppingListId != shoppingListId || prevToken != token)
            this.setDefaultActiveTab();
    }
    
    render() {
        const {location} = this.props;
        const {activeTab} = this.state;
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);
        const shoppingListId = getQueryParamNumber(location, QUERY_PARAMS.ID_SHOPPING_LIST);
        const token = getQueryParam(location, QUERY_PARAMS.SHOPPING_LIST_TOKEN);

        return <React.Fragment>
            <Helmet
                title={t`Nákupné zoznamy`}
            />
            <ListWithTableLayout
                title={<Trans>Nákupné zoznamy</Trans>}
                list={<ShoppingListsList/>}
                listTitle={<Trans>Všetky</Trans>}
                table={<ShoppingListDisplay
                        shoppingListId={shoppingListId}
                        customerId={customerId}
                        token={token}
                />}
                tableTitle={<Trans>Položky</Trans>}
                tableFirst={shoppingListId != null || token != null}
                activeTab={activeTab}
                setActiveTab={this.setActiveTab}
            />
        </React.Fragment>;
    }
}

export default locationHOC(ShoppingListsPage);