import React from 'react';
import PropTypes from 'prop-types';
import List from '../general/List';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import RemoveShoppingList from './shoppingListsList/RemoveShoppingList';
import EditShoppingList from './shoppingListsList/EditShoppingList';
import {navigateToParametrized, getQueryParam} from '../../lib/url';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import { ShoppingListDescArray } from '../../constants/propTypesDefinitions';
import { Icon } from 'antd';
import Tooltip from '../general/Tooltip';

/**
 * @fero
 */

class ShoppingListsList extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.SHOPPING_LISTS]: ShoppingListDescArray.isRequired,
    };

    onSelectShoppingList = (selectedShoppingListId) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ID_SHOPPING_LIST]: selectedShoppingListId,
            [QUERY_PARAMS.SHOPPING_LIST_TOKEN]: undefined,
        });
    };

    render() {
        const {location, [GLOBAL_DATA.SHOPPING_LISTS]: shoppingLists} = this.props;
        const selectedShoppingListId = getQueryParam(location, QUERY_PARAMS.ID_SHOPPING_LIST);
        if(shoppingLists.length == 0) {
            return <h2 className="m-3 color-disabled text-center">
                <Trans>Nemáte uložené žiadne nákupné zoznamy.</Trans>
            </h2>
        } else {
            return <List
                selectedValueId={selectedShoppingListId}
                values={shoppingLists}
                onItemClick={(value) => {
                    this.onSelectShoppingList(value.id)
                }}
            >
                {shoppingLists.map((shoppingList, index) => {
                    return <div
                        key={index}
                        className="d-flex align-items-center flex-wrap"
                    >
                        <span className="mx-2">
                            {shoppingList.name}
                        </span>
                        {shoppingList.token != null ?
                            <Tooltip title={<Trans>Zdieľané</Trans>}>
                                <Icon type="link"/>
                            </Tooltip> : null}
                        {selectedShoppingListId == shoppingList.id ? [
                            <EditShoppingList
                                key="edit"
                                shoppingList={shoppingList}
                            />,
                            <RemoveShoppingList
                                key="remove"
                                shoppingList={shoppingList}
                            />,
                        ] : null}
                    </div>
                })}
            </List>;
        }

    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.SHOPPING_LISTS])(ShoppingListsList));