import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import ShoppingListTable from './shoppingListDisplay/ShoppingListTable';
import ShoppingListLoad from './shoppingListDisplay/ShoppingListLoad';
import {concatUnique} from '../../lib/array';
import {GLOBAL_DATA} from '../../constants/globalData';
import {Trans, t} from '@lingui/macro';
import ShoppingListShare from './shoppingListDisplay/ShoppingListShare';
import { RIGHTS } from '../../constants/Rights';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { createFetchShoppingListDetails } from '../../backend/apiCalls';
import Spinner from '../graphics/Spinner';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import PageActionsLayout from '../general/PageActionsLayout';

/**
 * @fero
 */

class ShoppingListDisplay extends React.PureComponent {
    static propTypes = {
        shoppingListId: PropTypes.number,
        customerId: PropTypes.number,
        token: PropTypes.string,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedItemsIds: [],
            shoppingList: null,
            loading: false,
        };
    }

    fetchList = () => {
        const {shoppingListId, customerId, token, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        if(shoppingListId != null)
        {
            this.setState({loading: true});
            fetchHandler(
                createFetchShoppingListDetails(),
                {id: shoppingListId, id_customer: customerId, token: token},
                (result) => {
                    this.setState({
                        shoppingList: result,
                        selectedItemsIds: this.allShoppingListItemIdsToArray(result),
                        loading: false,
                    });
                }
            );
        }
        else
        {
            this.setState({
                shoppingList: null,
                selectedItemsIds: [],
                loading: false,
            });
        }
    };

    componentDidMount() {
        this.fetchList();
    }

    componentDidUpdate(prevProps) {
        const {shoppingListId: prevShoppingListId, customerId: prevCustomerId } = prevProps;
        const {shoppingListId, customerId} = this.props;
        if(shoppingListId != prevShoppingListId || customerId != prevCustomerId) {
            this.fetchList();
        }
    }

    allShoppingListItemIdsToArray = (details) => {
        return details != null && details.lines != null && Array.isArray(details.lines) ? 
            details.lines.map(item => item.id_item) : [];
    };

    setSelected = (selected, itemId) => {
        const {selectedItemsIds} = this.state;
        if (selected) 
        {
            this.setState({
                selectedItemsIds: concatUnique(selectedItemsIds, [itemId]),
            });
        } 
        else 
        {
            this.setState({
                selectedItemsIds: selectedItemsIds.filter(tmpItemId => tmpItemId != itemId),
            });
        }
    };

    setSelectedAll = (selected) => {
        const {shoppingList} = this.state;
        if (selected) 
        {
            this.setState({
                selectedItemsIds: this.allShoppingListItemIdsToArray(shoppingList),
            });
        } 
        else 
        {
            this.setState({
                selectedItemsIds: [],
            });
        }
    };

    render() {
        const { customerId, shoppingListId, [SESSION_ATTRIBUTES.RIGHTS]: rights } = this.props;
        const {selectedItemsIds, shoppingList, loading} = this.state;
        
        if (shoppingListId != null)
        { 
            const shoppingListChecked = shoppingList != null ? shoppingList : {};
            const isEditable = rights >= RIGHTS.CUSTOMER && shoppingListChecked.is_mine != 0;
            const allLines = shoppingListChecked.lines != null ? shoppingListChecked.lines : [];
            const selectedLines = Array.isArray(selectedItemsIds) ? 
                allLines.filter(line => selectedItemsIds.includes(line.id_item)) : 
                [];

            return <div className="full-size-height full-size-width px-3">
                <PageActionsLayout
                    actions={[
                        {
                            node: isEditable ? 
                                <ShoppingListShare
                                    shoppingList={shoppingListChecked}
                                /> : null
                        },
                        {
                            node: <ShoppingListLoad
                                shoppingList={shoppingListChecked}
                                customerId={customerId}
                                selectedItems={selectedLines}
                            />
                        }
                    ]}
                />
                <div className="px-3 d-flex align-items-center">
                    <h2>
                        {shoppingListChecked.name}
                    </h2>
                    { shoppingListChecked.is_mine == 0 ? 
                        <span className="mx-3">
                            <Trans>Zdieľané používateľom</Trans>
                            {' '}
                            {shoppingListChecked.user}
                        </span> : null
                    }
                </div>
                <div style={{height: 'calc(100% - 7rem)'}}>
                    <ShoppingListTable
                        selectedItemsIds={selectedItemsIds}
                        setSelected={this.setSelected}
                        setSelectedAll={this.setSelectedAll}
                        shoppingListItems={allLines}
                        isEditable={isEditable}
                        reload={this.fetchList}
                    />
                </div>
            </div>;
        }
        else 
        {
            return <div className="m-5 d-flex justify-content-center">
                <h1 className="d-inline color-disabled">
                    <Trans>Vyberte nákupný zoznam...</Trans>
                </h1>
            </div>;
        }
    }
}

export default  withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(ShoppingListDisplay));