import React from 'react';
import PropTypes from 'prop-types';
import ButtonFetch from '../../fetch/ButtonFetch';
import generalFailedPC from '../../fetch/generalFailedPC';
import CartAddResponse from '../../products/table/shoppingCartAdd/CartAddResponse';
import {createFetchShoppingListLoadItems} from '../../../backend/apiCalls';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import Tooltip from '../../general/Tooltip';
import {GLOBAL_DATA} from '../../../constants/globalData';
import withDataHOC from '../../dataProvider/withDataHOC';
import { ShoppingListDetails } from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */


class ShoppingListLoad extends React.PureComponent {
    static propTypes = {
        shoppingList: ShoppingListDetails.isRequired,
        customerId: PropTypes.number,
        selectedItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    refreshData = () => {
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.CART_CURRENT]);
    };

    render() {
        const {shoppingList, customerId, selectedItems} = this.props;
        const selectedItemIds = selectedItems.map(item => { return item.id_item; });
        const productsData = selectedItems.map(item => { 
            return {designation: item.designation, quantity: Number(item.quantity), quantityUnits: item.quantity_units};
        });
        const disabled = (selectedItemIds == null || selectedItemIds.length == 0);
        const fetchParams = {
            id_list: shoppingList.id,
            token: shoppingList.token,
            id_item: selectedItemIds,
            id_customer: customerId
        };
        return <ButtonFetch
            ref={node => this.loadButton = node}
            type="primary"
            icon="shopping-cart"
            disabled={disabled}
            values={{products: productsData}}
            Response={CartAddResponse}
            Failed={generalFailedPC(t`Pridanie do košíka zlyhalo`)}
            fetchFunction={createFetchShoppingListLoadItems(fetchParams)}
            onFinish={this.refreshData}
        >
            {' '}
            {disabled ? <Trans>Vyberte riadky zo zoznamu</Trans> : <Trans>Pridať vybrané riadky do košíka</Trans>}
        </ButtonFetch>;
    }
}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(ShoppingListLoad);