import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchShoppingListRemoveItems} from '../../../backend/apiCalls';
import {ShoppingListItem} from '../../../constants/propTypesDefinitions';
import Tooltip from '../../general/Tooltip';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */


class RemoveShoppingListItems extends React.PureComponent {
    static propTypes = {
        data: ShoppingListItem.isRequired,
        reload: PropTypes.func.isRequired,
        buttonClassName: PropTypes.string,
    };

    render() {
        const {data, reload, buttonClassName} = this.props;
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Vymazať</Trans>}>
                    <Button
                        size="small"
                        type="danger"
                        icon="close"
                        className={buttonClassName ? buttonClassName : ''}
                    />
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Odstránenie položky z nákupného zoznamu zoznamu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť položku?</Trans>,
                createFetchShoppingListRemoveItems(data.id_list, data.id_item),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť položku z nákupného zoznamu.`)}
        />;
    }

}

export default RemoveShoppingListItems;