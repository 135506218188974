import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import {Button, Form, Radio} from 'antd';
import {createFetchShoppingListShare} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import InputText from '../../general/InputText';
import { GLOBAL_DATA } from '../../../constants/globalData';
import withDataHOC from '../../dataProvider/withDataHOC';
import { getInputOnChangeEventValue } from '../../../lib/project';
import { ShoppingListDetails } from '../../../constants/propTypesDefinitions';
const FormItem = Form.Item;

/**
 * @dusan
 */

class ShoppingListShare extends React.PureComponent {
   static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        shoppingList: ShoppingListDetails.isRequired,
    };

    render() {
        const {shoppingList, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <FormModal
            openNode={
                <Button className="mx-1" icon="share-alt" type={shoppingList.token != null ? "primary" : ""}>
                    <span className="ml-2">
                        <Trans>Zdieľanie</Trans>
                    </span>
                </Button>
            }
            values={{shoppingList}}
            onFinishSuccessful={() => {
                reload([GLOBAL_DATA.SHOPPING_LISTS])
            }}
            title={<Trans>Zdieľať nákupný zoznam e-mailom</Trans>}
            Form={ShoppingListShareFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa zdieľať nákupný zoznam.`)}
        />;
    }
}

class ShoppingListShareForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            shoppingList: ShoppingListDetails.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disabled: false,
        };
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {shoppingList} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const formValuesWithId = merge(formValues, {id: shoppingList.id});
                createFetchShoppingListShare()(formValuesWithId).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose} = this.props;
        const {loading, disabled} = this.state;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Zdieľanie povolené</Trans>}
            >
                {getFieldDecorator('disabled', {initialValue: 0})(
                    <Radio.Group 
                        onChange={(e) => {
                            this.setState({disabled: getInputOnChangeEventValue(e)});
                        }}
                    >
                        <Radio.Button value={0}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={1}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            {disabled ? null : 
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Odoslať na e-mail</Trans>}>
                    {getFieldDecorator('e_mail', {rules:[mandatoryRule]})(
                        <InputText/>
                    )}
                </FormItem>
            }
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    {disabled ? <Trans>Vypnúť zdieľanie</Trans> : <Trans>Odoslať</Trans>}
                </Button>
            </FormItem>
        </Form>;
    }

}

const ShoppingListShareFormWrapped = Form.create()(ShoppingListShareForm);

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(ShoppingListShare);