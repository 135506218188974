import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import Tooltip from '../../general/Tooltip';
import {merge} from '../../../lib/object';
import {Button, Form, Input} from 'antd';
import {createFetchShoppingListEdit} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {ShoppingListDesc} from '../../../constants/propTypesDefinitions';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Trans, t} from '@lingui/macro';
const FormItem = Form.Item;

/**
 * @fero
 */

class EditShoppingList extends React.PureComponent {
    static propTypes = {
        shoppingList: ShoppingListDesc,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {shoppingList} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Upraviť nákupný zoznam</Trans>}>
                    <Button className="mx-1" icon="edit" size="small"/>
                </Tooltip>
            }
            values={{shoppingList: shoppingList}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.SHOPPING_LISTS, GLOBAL_DATA.SHOPPING_LIST_DETAILS])}}
            title={<Trans>Úprava nákupného zoznamu</Trans>}
            Form={ShoppingListFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť nákupný zoznam`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(EditShoppingList);

class ShoppingListForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            shoppingList: ShoppingListDesc,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {shoppingList} = values;
                const valuesWithId = merge(formValues, {id: shoppingList.id});
                createFetchShoppingListEdit()(valuesWithId).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {shoppingList = {}} = {}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('name', {rules:[mandatoryRule], initialValue: shoppingList.name})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {<Trans>Upraviť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }

}

const ShoppingListFormWrapped = Form.create()(ShoppingListForm);